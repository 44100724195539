.page-index header .header {
  border-bottom: none;
  background-color: transparent;
  top: 0.15rem;
}

@media (max-width: 1024px) {
  .page-index header .header {
    background-color: #fff;
  }
}

@media (max-width: 1024px) {
  .page-index header .header {
    top: 0;
  }
}

.page-index header .header.scroll {
  top: 0;
  border-bottom: .01rem solid #cccccc;
  background-color: #fff;
}

.page-index header .header.scroll .header-nav ul li:hover a {
  color: #000 !important;
}

.page-index header .header.scroll .header-nav ul li.active a {
  color: #014099 !important;
}

.page-index header .header.scroll .header-nav ul li a span::after {
  background-color: #092f60;
}

.page-index header .header .header-nav ul li:hover a {
  color: rgba(255, 255, 255, 0.7) !important;
}

.page-index header .header .header-nav ul li.active a {
  color: white !important;
}

.page-index header .header .header-nav ul li a {
  color: #fff;
}

.page-index header .header .header-nav ul li a span::after {
  background-color: transparent;
}

.page-index header .header .header-right .search {
  color: #fff;
}

@media (max-width: 1024px) {
  .page-index header .header .header-right .search {
    color: #333;
  }
}

.page-index header .header .header-right .search::after {
  background-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 1024px) {
  .page-index header .header .header-right .search::after {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.page-index header .header .header-right .language .language-title {
  color: #fff;
}

@media (max-width: 1024px) {
  .page-index header .header .header-right .language .language-title {
    color: #333;
  }
}

.index-common-title {
  color: #fff;
  font-weight: bold;
  font-size: 0.46rem;
}

@media (max-width: 1024px) {
  .index-common-title {
    font-size: 26px;
  }
}

.index-banner {
  background-color: #000;
  position: relative;
  height: 100vh;
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .banner-t h2 span, .index-banner .swiper .swiper-slide.swiper-slide-active .banner-t h1 span {
  opacity: 1;
  transform: translateY(0);
}

.index-banner .swiper .swiper-slide.swiper-slide-active .banner-t h1 span {
  transition-delay: .3s;
}

.index-banner .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-banner .swiper .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.index-banner .swiper .swiper-slide b.pc-img {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.pc-img {
    display: none;
  }
}

.index-banner .swiper .swiper-slide b.mob-img {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.mob-img {
    display: block;
  }
}

.index-banner .swiper .swiper-slide .banner-t {
  color: #fff;
  position: absolute;
  bottom: 30%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  line-height: 1;
  padding-left: 7.8%;
  padding-right: 7.8%;
}

.index-banner .swiper .swiper-slide .banner-t h2 span, .index-banner .swiper .swiper-slide .banner-t h1 span {
  display: block;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  transform: translateY(100%);
  opacity: 0;
}

.index-banner .swiper .swiper-slide .banner-t h2 {
  overflow: hidden;
  font-size: 0.26rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .banner-t h2 {
    font-size: 16px;
  }
}

.index-banner .swiper .swiper-slide .banner-t h1 {
  overflow: hidden;
  font-weight: bold;
  margin-top: 0.15rem;
  font-size: 0.7rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .banner-t h1 {
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .banner-t h1 {
    font-size: 36px;
  }
}

.index-banner .banner-contral {
  position: absolute;
  bottom: 20%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7.8%;
  padding-right: 7.8%;
}

.index-banner .banner-contral .left-btn {
  display: none;
}

.index-banner .banner-contral .left-btn .common-btn {
  border: 0.01rem solid rgba(255, 255, 255, 0.4);
}

.index-banner .banner-contral .left-btn .common-btn:hover .icon {
  background-color: transparent;
}

.index-banner .banner-contral .left-btn .common-btn .icon {
  background-color: #092f60;
}

.index-banner .banner-contral .left-btn .common-btn .icon::after {
  background-color: #092f60;
}

.index-banner .banner-contral .left-btn .common-btn .icon .iconfont {
  color: #fff;
}

.index-banner .banner-contral .left-btn .common-btn .t {
  width: 1.15rem;
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .left-btn .common-btn .t {
    width: 81px;
  }
}

.index-banner .banner-contral .line {
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.4);
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .line {
    height: 1px;
  }
}

.index-banner .banner-contral .right-contral {
  position: relative;
  position: relative;
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral {
    left: 0;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral {
    top: 200%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral {
    position: absolute;
  }
}

.index-banner .banner-contral .right-contral .swiper-pagination {
  position: static;
  height: 0.2rem;
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination {
    height: 20px;
  }
}

.index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet {
  border-radius: 0;
  margin: 0;
  background: none;
  position: relative;
  opacity: 1;
  height: 0.2rem;
  width: 1rem;
  margin-left: 0.02rem;
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet {
    height: 20px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet {
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet {
    margin-left: 2px;
  }
}

.index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  animation: bannerpage 6s linear;
}

.index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::before, .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  height: 0.03rem;
  top: calc(50% - .01rem);
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::before, .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::after {
    height: 3px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::before, .index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::after {
    top: calc(50% - .1px);
  }
}

.index-banner .banner-contral .right-contral .swiper-pagination .swiper-pagination-bullet::before {
  transform-origin: left;
  transform: scaleX(0);
  z-index: 1;
  background-color: #e60012;
}

.index-banner .banner-contral .right-contral .swiper-page {
  position: absolute;
  color: #fff;
  left: 0.03rem;
  bottom: 0.25rem;
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral .swiper-page {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-contral .right-contral .swiper-page {
    transform: translateX(-50%);
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-contral .right-contral .swiper-page {
    bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .index-banner .banner-contral .right-contral .swiper-page {
    bottom: auto;
    top: 100%;
  }
}

@keyframes bannerpage {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.section1 .cont1 {
  background: url(./images/index_bg1.jpg) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .section1 .cont1 {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .section1 .cont1 {
    min-height: 45vh;
  }
}

.section1 .cont1 .title-t {
  text-align: center;
  border-bottom: .01rem solid #000;
  line-height: 1.2;
  color: #092f60;
  padding-bottom: 0.45rem;
  margin-bottom: 0.45rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .title-t {
    padding-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 .title-t {
    margin-bottom: 20px;
  }
}

.section1 .cont1 .title-t h2 {
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .title-t h2 {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 .title-t h2 {
    margin-bottom: 5px;
  }
}

.section1 .cont1 .title-t h1 {
  font-weight: bold;
  font-size: 0.46rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .title-t h1 {
    font-size: 22px;
  }
}

.section1 .cont1 .num-cont {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  font-size: 0.18rem;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont {
    font-size: 16px;
  }
}

.section1 .cont1 .num-cont .item {
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section1 .cont1 .num-cont .item {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section1 .cont1 .num-cont .item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .section1 .cont1 .num-cont .item {
    margin-bottom: 20px;
  }
}

.section1 .cont1 .num-cont .item .desc {
  white-space: nowrap;
  margin-bottom: 0.15rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .desc {
    margin-bottom: 10px;
  }
}

.section1 .cont1 .num-cont .item .n {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  line-height: 0.46rem;
}

@media (max-width: 767px) {
  .section1 .cont1 .num-cont .item .n {
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n {
    line-height: 26px;
  }
}

.section1 .cont1 .num-cont .item .n .num-t {
  color: #092f60;
  font-weight: bold;
  font-size: 0.36rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num-t {
    font-size: 22px;
  }
}

.section1 .cont1 .num-cont .item .n .num {
  position: relative;
  font-weight: bold;
  color: #092f60;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.05rem;
  font-size: 0.46rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num {
    margin-right: 5px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num {
    font-size: 26px;
  }
}

.section1 .cont1 .num-cont .item .n .num .placeholder-num {
  opacity: 0;
  letter-spacing: 0.02rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num .placeholder-num {
    letter-spacing: 4px;
  }
}

.section1 .cont1 .num-cont .item .n .num .scroll-num {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 0.46rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums {
    height: 26px;
  }
}

.section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataOne {
  width: 0.28rem;
  height: 0.46rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataOne {
    width: 18px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataOne {
    height: 26px;
  }
}

.section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataBoc {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataBoc .tt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataBoc .tt span {
  width: 100%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 0.03rem;
}

@media (max-width: 1024px) {
  .section1 .cont1 .num-cont .item .n .num .scroll-num .dataNums .dataBoc .tt span {
    padding-top: 3px;
  }
}

.section1 .cont2 {
  padding-top: 0.7rem;
  padding-bottom: 0.9rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont2 {
    padding-bottom: 30px;
  }
}

.section1 .cont2 .swiper {
  overflow: hidden;
  position: relative;
  padding-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper {
    padding-bottom: 20px;
  }
}

.section1 .cont2 .swiper .swiper-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-slide > div {
    height: 80px;
  }
}

@media (max-width: 767px) {
  .section1 .cont2 .swiper .swiper-slide > div {
    height: 60px;
  }
}

.section1 .cont2 .swiper .swiper-slide img {
  display: block;
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: 50%;
}

.section1 .cont2 .swiper .swiper-pagination {
  bottom: -0.1rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-pagination {
    bottom: -10px;
  }
}

.section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  position: relative;
  border-radius: 0;
  width: 18px;
  margin: 0;
  width: 0.18rem;
  height: 0.4rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 18px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 20px;
  }
}

.section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 0.07rem;
  height: 0.07rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 7px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont2 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 7px;
  }
}

.section1 .cont2 .link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .link {
    margin-top: 10px;
  }
}

.section1 .cont2 .link::after, .section1 .cont2 .link::before {
  content: '';
  display: block;
  flex-grow: 1;
  background-color: #000;
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .link::after, .section1 .cont2 .link::before {
    height: 1px;
  }
}

.section1 .cont2 .link .common-btn {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

@media (max-width: 1024px) {
  .section1 .cont2 .link .common-btn {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .section1 .cont2 .link .common-btn {
    margin-right: 10px;
  }
}

.section2 {
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  background: #e0e0e0;
  display: flex;
  height: calc(100vh - 1.2rem);
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .section2 {
    margin-top: 0px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .section2 {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .section2 {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-left: 10px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-bottom: 30px;
  }
}

.section2::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #e0e0e0;
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  height: 0.7rem;
}

@media (max-width: 1024px) {
  .section2::after {
    height: 0px;
  }
}

.section2 .sec2-text {
  top: 43%;
  z-index: 5;
  text-align: nnull;
  transform: translateY(-50%);
  position: absolute;
  width: 29%;
  right: 18.75%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    position: static;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-text {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .section2 .sec2-text {
    right: 16%;
  }
}

@media (max-width: 1366px) {
  .section2 .sec2-text {
    right: 13.25%;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text {
    right: 10.5%;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-text {
    right: 7.75%;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    right: 5%;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text {
    margin-bottom: 20px;
  }
}

.section2 .sec2-text .title {
  font-weight: bold;
  color: #000;
  font-size: 0.42rem;
  margin-bottom: 0.15rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-text .title {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text .title {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text .title {
    margin-bottom: 5px;
  }
}

.section2 .sec2-text .desc {
  line-height: 1.88;
  font-size: 0.18rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 1600px) {
  .section2 .sec2-text .desc {
    line-height: 1.824;
  }
}

@media (max-width: 1366px) {
  .section2 .sec2-text .desc {
    line-height: 1.768;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text .desc {
    line-height: 1.712;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-text .desc {
    line-height: 1.656;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text .desc {
    line-height: 1.6;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text .desc {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-text .desc {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-text .desc {
    margin-bottom: 10px;
  }
}

.section2 .sec2-text .link .common-btn {
  border-color: #868686;
}

.section2 .sec2-text .link .common-btn:hover .icon {
  background-color: transparent;
}

.section2 .sec2-text .link .common-btn .icon {
  background-color: #092f60;
}

.section2 .sec2-text .link .common-btn .icon::after {
  background-color: #092f60;
}

.section2 .sec2-text .link .common-btn .icon .iconfont {
  color: #fff;
}

.section2 .sec2-text .link .common-btn .t {
  width: 1.15rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-text .link .common-btn .t {
    width: 81px;
  }
}

.section2 .map-cont {
  width: 100%;
  margin: 0 auto;
  position: relative;
  white-space: nowrap;
  display: block;
}

.section2 .map-cont .map {
  display: block;
  width: 100%;
  height: auto;
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  transform: scale(0);
}

@media (max-width: 767px) {
  .section2 .map-cont .map {
    transform: scale(1);
  }
}

.section2 .map-cont .location-list {
  color: #000;
  font-size: 0.17rem;
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list {
    font-size: 12px;
  }
}

.section2 .map-cont .location-list .list1 li {
  position: absolute;
  z-index: 2;
  opacity: 0;
  width: 0.01rem;
  width: 0.01rem;
}

@media (max-width: 767px) {
  .section2 .map-cont .location-list .list1 li {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li {
    width: 1px;
  }
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li {
    width: 1px;
  }
}

.section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0) scale(0);
  background-color: #d4dde9;
  z-index: -1;
  transform-origin: center;
  animation: wave 2s infinite;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  width: 1.6rem;
  height: 1.6rem;
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    width: 100px;
  }
}

@media (max-width: 991px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    width: 70px;
  }
}

@media (max-width: 767px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    width: 50px;
  }
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    height: 100px;
  }
}

@media (max-width: 991px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    height: 70px;
  }
}

@media (max-width: 767px) {
  .section2 .map-cont .location-list .list1 li::before, .section2 .map-cont .location-list .list1 li::after {
    height: 50px;
  }
}

.section2 .map-cont .location-list .list1 li .iconfont {
  color: #233f77;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  line-height: 1;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  font-size: 0.4rem;
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li .iconfont {
    font-size: 20px;
  }
}

.section2 .map-cont .location-list .list1 li span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-30%);
  top: 0;
  z-index: 1;
  background: url(./images/index_img_local_bg.png) no-repeat top center;
  background-size: contain;
  width: 0.56rem;
  height: 0.18rem;
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li span {
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .section2 .map-cont .location-list .list1 li span {
    height: 10px;
  }
}

.section2 .map-cont .location-list .list1 li:nth-child(1) {
  top: 25.5%;
  left: 28%;
}

.section2 .map-cont .location-list .list1 li:nth-child(1)::before {
  animation-delay: 0s;
}

.section2 .map-cont .location-list .list1 li:nth-child(1)::after {
  animation-delay: .8s;
}

.section2 .map-cont .location-list .list1 li:nth-child(1) .iconfont {
  animation-delay: .1s;
}

.section2 .map-cont .location-list .list1 li:nth-child(2) {
  top: 73%;
  left: 14.5%;
}

.section2 .map-cont .location-list .list1 li:nth-child(2)::before {
  animation-delay: .5s;
}

.section2 .map-cont .location-list .list1 li:nth-child(2)::after {
  animation-delay: 1.3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(2) .iconfont {
  animation-delay: 1s;
}

.section2 .map-cont .location-list .list1 li:nth-child(3) {
  top: 91.5%;
  left: 13.5%;
}

.section2 .map-cont .location-list .list1 li:nth-child(3)::before {
  animation-delay: .3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(3)::after {
  animation-delay: 1.1s;
}

.section2 .map-cont .location-list .list1 li:nth-child(3) .iconfont {
  animation-delay: 2s;
}

.section2 .map-cont .location-list .list1 li:nth-child(4) {
  top: 64.5%;
  left: 40%;
}

.section2 .map-cont .location-list .list1 li:nth-child(4)::before {
  animation-delay: .3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(4)::after {
  animation-delay: 1.1s;
}

.section2 .map-cont .location-list .list1 li:nth-child(4) .iconfont {
  animation-delay: 3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(5) {
  top: 80%;
  left: 51%;
}

.section2 .map-cont .location-list .list1 li:nth-child(5)::before {
  animation-delay: .5s;
}

.section2 .map-cont .location-list .list1 li:nth-child(5)::after {
  animation-delay: 1.3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(5) .iconfont {
  animation-delay: 4s;
}

.section2 .map-cont .location-list .list1 li:nth-child(6) {
  top: 14%;
  left: 70.5%;
}

.section2 .map-cont .location-list .list1 li:nth-child(6)::before {
  animation-delay: .5s;
}

.section2 .map-cont .location-list .list1 li:nth-child(6)::after {
  animation-delay: 1.3s;
}

.section2 .map-cont .location-list .list1 li:nth-child(6) .iconfont {
  animation-delay: 5s;
}

.section2.show .location-list .list1 li, .section2.show .location-list .list2 li, .section2.show .location-list .list3 li {
  opacity: 1;
}

.section2.show .location-list .list1 li:nth-child(1) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0s;
}

.section2.show .location-list .list1 li:nth-child(2) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0.1s;
}

.section2.show .location-list .list1 li:nth-child(3) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0.2s;
}

.section2.show .location-list .list1 li:nth-child(4) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0.3s;
}

.section2.show .location-list .list1 li:nth-child(5) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0.4s;
}

.section2.show .location-list .list1 li:nth-child(6) {
  transition: opacity 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89) 0.5s;
}

@keyframes wave {
  0% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

@keyframes shak {
  0% {
    color: #092f60;
  }
  13% {
    color: #fff;
  }
  25% {
    color: #092f60;
  }
  100% {
    color: #092f60;
  }
}

.section3 {
  position: relative;
}

.section3.fixed .cont {
  position: fixed;
  top: 1.2rem;
}

.section3.abs .cont {
  position: absolute;
  top: auto;
  bottom: 0;
}

.section3 .cont {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  height: calc(100vh - 1.2rem);
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .section3 .cont {
    position: static;
  }
}

@media (max-width: 1024px) {
  .section3 .cont {
    display: block;
  }
}

@media (max-width: 1024px) {
  .section3 .cont {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .section3 .cont {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont {
    padding-bottom: 30px;
  }
}

.section3 .cont .section3-title {
  width: 100%;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .section3 .cont .section3-title {
    margin-bottom: 20px;
  }
}

.section3 .cont .section3-title .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section3 .cont .section3-title .container .index-common-title {
  color: #000;
}

.section3 .cont .section3-title .container .progress {
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  height: 0.01rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

@media (max-width: 1024px) {
  .section3 .cont .section3-title .container .progress {
    height: 1px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .section3-title .container .progress {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .section3-title .container .progress {
    margin-right: 10px;
  }
}

.section3 .cont .section3-title .container .progress > div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #e60012;
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .section3 .cont .section3-title .container .progress > div {
    height: 1px;
  }
}

.section3 .cont ul {
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  display: inline-flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul {
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .section3 .cont ul {
    display: block;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul {
    padding-left: 5%;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul {
    padding-right: 5%;
  }
}

.section3 .cont ul li {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  width: 22vw;
  height: 60vh;
  margin-bottom: 0.5rem;
}

@media (max-width: 1600px) {
  .section3 .cont ul li {
    width: 28vw;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .section3 .cont ul li {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .section3 .cont ul li {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .section3 .cont ul li {
    height: 350px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li {
    margin-bottom: 20px;
  }
}

.section3 .cont ul li::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #1e2e53;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
}

.section3 .cont ul li:not(:last-child) {
  margin-right: 0.6rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li:not(:last-child) {
    margin-right: 0;
  }
}

.section3 .cont ul li:hover::after {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .section3 .cont ul li:hover::after {
    transform: scale(1.05);
  }
}

.section3 .cont ul li:hover .item-bg {
  opacity: .6;
  box-shadow: 0px 0.1rem 0.25rem rgba(0, 0, 0, 0.6);
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .section3 .cont ul li:hover .item-bg {
    transform: scale(1.05);
  }
}

.section3 .cont ul li:hover .item-text a .desc {
  opacity: 1;
  transform: translateY(0);
}

.section3 .cont ul li .item-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-bg {
    opacity: 0.6;
  }
}

.section3 .cont ul li .item-text {
  height: 100%;
  color: #fff;
}

.section3 .cont ul li .item-text a {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.55rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a {
    padding-top: 30px;
  }
}

.section3 .cont ul li .item-text a .tag {
  display: flex;
  margin-bottom: 0.45rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag {
    margin-bottom: 15px;
  }
}

.section3 .cont ul li .item-text a .tag span {
  flex-shrink: 0;
  display: inline-flex;
  border: .01rem solid #fff;
  align-items: center;
  border-radius: .5rem;
  font-size: 0.14rem;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span {
    padding-left: 15px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span {
    padding-right: 15px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span {
    padding-bottom: 5px;
  }
}

.section3 .cont ul li .item-text a .tag span:not(:last-child) {
  margin-right: 0.05rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span:not(:last-child) {
    margin-right: 5px;
  }
}

.section3 .cont ul li .item-text a .tag span .iconfont {
  line-height: 1;
  font-size: 0.22rem;
  margin-right: 0.1rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span .iconfont {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .tag span .iconfont {
    margin-right: 10px;
  }
}

.section3 .cont ul li .item-text a .desc {
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  opacity: 0;
  transform: translateY(20%);
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .desc {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .desc {
    transform: translateY(0);
  }
}

.section3 .cont ul li .item-text a .title {
  font-weight: bold;
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.5);
  font-size: 0.3rem;
  padding-bottom: 0.2rem;
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .title {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .section3 .cont ul li .item-text a .title {
    padding-bottom: 10px;
  }
}

.section4 {
  position: relative;
  background: url(./images/index_service_bg.jpg) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  display: flex;
  height: calc(100vh - 1.2rem);
  padding-left: 15.6%;
  padding-right: 15.6%;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .section4 {
    display: block;
  }
}

@media (max-width: 1024px) {
  .section4 {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .section4 {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .section4 {
    padding-left: 8%;
  }
}

@media (max-width: 991px) {
  .section4 {
    padding-left: 6.5%;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-left: 5%;
  }
}

@media (max-width: 1024px) {
  .section4 {
    padding-right: 8%;
  }
}

@media (max-width: 991px) {
  .section4 {
    padding-right: 6.5%;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-bottom: 30px;
  }
}

.section4::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
}

.section4 .cont {
  position: relative;
  z-index: 2;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .section4 .cont {
    display: block;
  }
}

.section4 .cont > .left {
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.7rem;
  width: 40%;
}

@media (max-width: 1024px) {
  .section4 .cont > .left {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left {
    padding-left: 15px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left {
    padding-right: 15px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left {
    width: 100%;
  }
}

.section4 .cont > .left .title {
  font-weight: bold;
  color: #092f60;
  font-size: 0.42rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .left .title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left .title {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .title {
    margin-bottom: 15px;
  }
}

.section4 .cont > .left .desc {
  color: #000;
  line-height: 2.22;
  font-size: 0.18rem;
  margin-bottom: 1rem;
}

@media (max-width: 1600px) {
  .section4 .cont > .left .desc {
    line-height: 2.136;
  }
}

@media (max-width: 1366px) {
  .section4 .cont > .left .desc {
    line-height: 2.052;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .desc {
    line-height: 1.968;
  }
}

@media (max-width: 991px) {
  .section4 .cont > .left .desc {
    line-height: 1.884;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left .desc {
    line-height: 1.8;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .desc {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .desc {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left .desc {
    margin-bottom: 30px;
  }
}

.section4 .cont > .left .tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section4 .cont > .left .tab .item {
  text-align: center;
  color: #092f60;
  cursor: pointer;
}

.section4 .cont > .left .tab .item:not(:last-child) {
  margin-right: 0.7rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .left .tab .item:not(:last-child) {
    margin-right: 20px;
  }
}

.section4 .cont > .left .tab .item.active .icon {
  color: #fff;
  background-color: #092f60;
}

.section4 .cont > .left .tab .item .icon {
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  border: 0.01rem solid #092f60;
  margin-bottom: 0.1rem;
  width: 0.58rem;
  height: 0.58rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .icon {
    border: 1px solid #092f60;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .icon {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .icon {
    width: 50px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .icon {
    height: 50px;
  }
}

.section4 .cont > .left .tab .item .icon .iconfont {
  font-size: 0.4rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .icon .iconfont {
    font-size: 36px;
  }
}

.section4 .cont > .left .tab .item .t {
  font-weight: bold;
  letter-spacing: 0.05rem;
  padding-left: 0.05rem;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .t {
    letter-spacing: 3px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .t {
    padding-left: 3px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .left .tab .item .t {
    font-size: 16px;
  }
}

.section4 .cont > .right {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 60%;
}

@media (max-width: 767px) {
  .section4 .cont > .right {
    width: 100%;
  }
}

.section4 .cont > .right .img-swiper {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .section4 .cont > .right .img-swiper {
    height: 240px;
  }
}

.section4 .cont > .right .img-swiper .swiper {
  height: 100%;
}

.section4 .cont > .right .img-swiper .swiper .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section4 .cont > .right .text-swiper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to top, #092f60, rgba(9, 47, 96, 0));
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper {
    padding-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper {
    padding-top: 10px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper {
    padding-right: 20px;
  }
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide {
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  font-size: 0.18rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide {
    font-size: 14px;
  }
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t {
  width: 100%;
  text-align: center;
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex {
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex {
    display: block;
  }
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item {
  flex-shrink: 0;
  text-align: left;
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item:not(:last-child) {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item:not(:last-child) {
    margin-bottom: 5px;
  }
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title {
  position: relative;
  font-weight: bold;
  font-size: 0.18rem;
  margin-bottom: 0.1rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title {
    margin-bottom: 0;
  }
}

.section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title::after {
  content: '';
  display: block;
  background-color: #a2aec0;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: -0.1rem;
  width: 0.03rem;
  height: 0.22rem;
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title::after {
    left: -8px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title::after {
    width: 2px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont > .right .text-swiper .swiper .swiper-slide .t-flex .t-item .desc-title::after {
    height: 18px;
  }
}

.section5 {
  background-color: #e7e7e7;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

@media (max-width: 1024px) {
  .section5 {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-bottom: 30px;
  }
}

.section5 .section5-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.45rem;
}

@media (max-width: 1024px) {
  .section5 .section5-title {
    margin-bottom: 15px;
  }
}

.section5 .section5-title .index-common-title {
  color: #000;
}

.section5 .section5-title .line {
  flex-grow: 1;
  background-color: #000;
  height: 0.01rem;
  margin-left: 0.4rem;
  margin-right: 0.2rem;
}

@media (max-width: 1024px) {
  .section5 .section5-title .line {
    height: 1px;
  }
}

@media (max-width: 1024px) {
  .section5 .section5-title .line {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .section5 .section5-title .line {
    margin-right: 10px;
  }
}

.section5 .sec5-swiper {
  padding-left: 12.5%;
  padding-right: 12.5%;
}

@media (max-width: 1600px) {
  .section5 .sec5-swiper {
    padding-left: 11%;
  }
}

@media (max-width: 1366px) {
  .section5 .sec5-swiper {
    padding-left: 9.5%;
  }
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper {
    padding-left: 8%;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-swiper {
    padding-left: 6.5%;
  }
}

@media (max-width: 767px) {
  .section5 .sec5-swiper {
    padding-left: 5%;
  }
}

@media (max-width: 1600px) {
  .section5 .sec5-swiper {
    padding-right: 11%;
  }
}

@media (max-width: 1366px) {
  .section5 .sec5-swiper {
    padding-right: 9.5%;
  }
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper {
    padding-right: 8%;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-swiper {
    padding-right: 6.5%;
  }
}

@media (max-width: 767px) {
  .section5 .sec5-swiper {
    padding-right: 5%;
  }
}

.section5 .sec5-swiper .rel-cont {
  position: relative;
}

.section5 .sec5-swiper .rel-cont .swiper-button-next, .section5 .sec5-swiper .rel-cont .swiper-button-prev {
  border: .01rem solid #000;
  padding: .02rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
  border-radius: 50px;
  box-sizing: content-box;
  background-color: #000;
  color: #fff;
  pointer-events: all;
  opacity: .5;
  transition: opacity .4s;
  transform: translateY(-0.5rem);
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next, .section5 .sec5-swiper .rel-cont .swiper-button-prev {
    transform: translateY(-34px);
  }
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next, .section5 .sec5-swiper .rel-cont .swiper-button-prev {
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next, .section5 .sec5-swiper .rel-cont .swiper-button-prev {
    height: 30px;
  }
}

.section5 .sec5-swiper .rel-cont .swiper-button-next::after, .section5 .sec5-swiper .rel-cont .swiper-button-prev::after {
  font-family: 'iconfont';
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next::after, .section5 .sec5-swiper .rel-cont .swiper-button-prev::after {
    font-size: 12px;
  }
}

.section5 .sec5-swiper .rel-cont .swiper-button-next:hover, .section5 .sec5-swiper .rel-cont .swiper-button-prev:hover {
  opacity: 1;
}

.section5 .sec5-swiper .rel-cont .swiper-button-next.swiper-button-disabled, .section5 .sec5-swiper .rel-cont .swiper-button-prev.swiper-button-disabled {
  opacity: .3;
}

.section5 .sec5-swiper .rel-cont .swiper-button-next {
  right: auto;
  left: 103%;
}

@media (max-width: 991px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next {
    right: 10px;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-next {
    left: auto;
  }
}

.section5 .sec5-swiper .rel-cont .swiper-button-next::after {
  content: '\e8d4';
}

.section5 .sec5-swiper .rel-cont .swiper-button-prev {
  left: auto;
  right: 103%;
}

@media (max-width: 991px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-prev {
    left: 10px;
  }
}

@media (max-width: 991px) {
  .section5 .sec5-swiper .rel-cont .swiper-button-prev {
    right: auto;
  }
}

.section5 .sec5-swiper .rel-cont .swiper-button-prev::after {
  content: '\eaf3';
}

.section5 .swiper {
  overflow: hidden;
}

.section5 .swiper .swiper-slide:hover .item-img b {
  transform: scale(1.1);
}

.section5 .swiper .swiper-slide .item-t {
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 0.8rem;
  font-size: 0.22rem;
  margin-top: 0.2rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t {
    height: 58px;
  }
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t {
    margin-top: 10px;
  }
}

.section5 .swiper .swiper-pagination {
  position: static;
  margin-top: 0.25rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-pagination {
    margin-top: 5px;
  }
}

.section5 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  transition: 0.4s cubic-bezier(0.71, 0.17, 0.3, 0.89);
  position: relative;
  border-radius: 0;
  margin: 0;
  width: 0.18rem;
  height: 0.4rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 18px;
  }
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 20px;
  }
}

.section5 .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 0.07rem;
  height: 0.07rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 7px;
  }
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 7px;
  }
}
