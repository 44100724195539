@import "../../assets/styles/utils";

.page-index{
  header{
    .header{
      border-bottom: none;
      @include res(background-color,transparent,(md:#fff));
      @include res(top,.15rem,(md:0));
      &.scroll{
        top: 0;
        border-bottom: .01rem solid #cccccc;
        background-color: #fff;
        .header-nav ul li{
          &:hover{
            a{
              color: #000 !important;
            }
          }
          &.active{
            a{
              color: $color-main-light !important;
            }
          }
          a{
            span::after{
              background-color: $color-main;
            }
          }
        } 
      }
      .header-nav ul li{
        &:hover{
          a{
            color: rgba(255,255,255,.7) !important;
          }
        }
        &.active{
          a{
            color: rgba(255,255,255,1) !important;
          }
        }
        a{
          color: #fff;
          span::after{
            background-color: transparent;
          }
        }
      } 
      .header-right{
        .search{
          @include res(color,#fff,(md:#333));
          &::after{
            @include res(background-color,rgba(255,255,255,.3),(md:rgba(0,0,0,.3)));
          }
        }
        .language{
          .language-title{
            @include res(color,#fff,(md:#333));
          }
        }
      }
    }
  }
}

.index-common-title{
  color: #fff;
  font-weight: bold;
  @include res(font-size,.46rem,(md:26px));
}
.index-banner{
  background-color: #000;
  position: relative;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    .swiper-slide{
      position: relative;
      &.swiper-slide-active{
        .banner-t{
          h2,h1{
            span{
              opacity: 1;
              transform: translateY(0);
            }
          }
          h1{
            span{
              transition-delay: .3s;
            }
          }
        }
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      b{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.pc-img{
          @include res(display,block,(sm:none));
        }
        &.mob-img{
          @include res(display,none,(sm:block));
        }
      }
      .banner-t{
        color: #fff;
        position: absolute;
        bottom: 30%;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 2;
        line-height: 1;
        @include res(padding-left,7.8%);
        @include res(padding-right,7.8%);
        h2,h1{
          span{
            display: block;
            transition: $anime-duration $anime-bezier;
            transform: translateY(100%);
            opacity: 0;
          }
        }
        h2{
          overflow: hidden;
          @include res(font-size,.26rem, (md:16px));
        }
        h1{
          overflow: hidden;
          font-weight: bold;
          @include res(margin-top,.15rem,(md:10px));
          @include res(font-size,.7rem,(md:36px));
        }
      }
    }
  }
  .banner-contral{
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(padding-left,7.8%);
    @include res(padding-right,7.8%);
    .left-btn{
      display: none;
      .common-btn{
        border: .01rem solid rgba(255,255,255,.4);
        &:hover{
          .icon{
            background-color: transparent;
          }
        }
        .icon{
          background-color: $color-main;
          &::after{
            @include res(background-color,$color-main);
          }
          .iconfont{
            @include res(color,#fff);
          }
        }
        .t{
          @include res(width,1.15rem, (md:81px));
        }
      }
    }
    .line{
      flex-grow: 1;
      background-color: rgba(255,255,255,.4);
      @include res(height,.01rem,(md:1px));
    }
    .right-contral{
      position: relative;
      @include res(width,null,(xs:100%));
      @include res(left,null,(xs:0));
      @include res(top,null,(xs:200%));
      @include res(position,relative,(xs:absolute));
      .swiper-pagination{
        position: static;
        @include res(height,.2rem,(md:20px));
        .swiper-pagination-bullet{
          border-radius: 0;
          margin: 0;
          background: none;
          position: relative;
          opacity: 1;
          @include res(height,.2rem,(md:20px));
          @include res(width,1rem, (md:60px));
          @include res(margin-left,.02rem, (md:2px));
          &.swiper-pagination-bullet-active{
            &::before{
              animation: bannerpage 6s linear;
            }
          }
          &::before,&::after{
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            background-color: rgba(255,255,255,.6);
            @include res(height,.03rem,(md:3px));
            @include res(top,calc(50% - .01rem),(md:calc(50% - .1px)));
          }
          &::before{
            transform-origin: left;
            transform: scaleX(0);
            z-index: 1;
            background-color: #e60012;
          }
        }
      }
      .swiper-page{
        position: absolute;
        color: #fff;
        @include res(left,.03rem,(xs:50%));
        @include res(transform,null,(xs:translateX(-50%)));
        @include res(bottom,.25rem,(md:15px));
        @media screen and(max-width:767px){
          bottom: auto;
          top: 100%;
        }
      }
    }
  }
}

@keyframes bannerpage {
  0%{
    transform: scaleX(0);
  }
  100%{
    transform: scaleX(1);
  }
}

.section1{
  .cont1{
    background: url(./images/index_bg1.jpg) no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    @include res(padding-top,.65rem,(md:30px));
    @include res(padding-bottom,.65rem, (md:30px));
    @media screen and(max-width:767px) {
      padding-bottom: 5px;
    }
    @media screen and(min-width: 1025px) {
      min-height: 45vh;
    }
    .title-t{
      text-align: center;
      border-bottom: .01rem solid #000;
      line-height: 1.2;
      color: $color-main;
      @include res(padding-bottom,.45rem, (md:20px));
      @include res(margin-bottom,.45rem, (md:20px));
      h2{
        @include res(font-size,.24rem, (md:18px));
        @include res(margin-bottom,.1rem,(md:5px));
      }
      h1{
        font-weight: bold;
        @include res(font-size,.46rem,(md:22px));
      }
    }
    .num-cont{
      display: flex;
      flex-wrap: wrap;
      color: #000;
      @include res(font-size,.18rem,(md:16px));
      @include res(justify-content,space-between);
      .item{
        @include res(text-align,left,(xs:center));
        @include res(width,null,(xs:50%));
        @include res(margin-bottom,0,(xs:20px));
        // &:last-child{
        //   @include res(min-width,null,(xs:100%));
        // }
        .desc{
          white-space: nowrap;
          @include res(margin-bottom,.15rem, (md:10px));
        }
        .n{
          display: flex;
          align-items: flex-end;
          @include res(justify-content,flex-start,(xs:center));
          @include res(line-height,.46rem,(md:26px));
          .num-t{
            color: $color-main;
            font-weight: bold;
            @include res(font-size,.36rem, (md:22px));
          }
          .num {
            position: relative;
            font-weight: bold;
            color: $color-main;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include res(margin-right,.05rem,(md:5px));
            @include res(font-size,.46rem,(md:26px));
            .placeholder-num{
              opacity: 0;
              @include res(letter-spacing,.02rem,(md:4px));
            }
            .scroll-num{
              position: absolute;
              top: 0;
              left: 0;
              overflow: hidden;
              .dataNums{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                @include res(height,.46rem,(md:26px));
                .dataOne{
                  @include res(width,.28rem, (md:18px));
                  @include res(height,.46rem,(md:26px));
                }
                .dataBoc {
                  position: relative; 
                  width: 100%; 
                  height: 100%; 
                  overflow: hidden;
                  .tt {
                    position: absolute; 
                    top: 0;  
                    left: 0; 
                    width: 100%;  
                    height: 100%;
                    span{
                      width:100%;
                      height:100%;
                      float: left;
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      @include res(padding-top,.03rem,(md:3px));
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cont2{
    @include res(padding-top,.7rem,(md:20px));
    @include res(padding-bottom,.9rem, (md:30px));
    .swiper{
      overflow: hidden;
      position: relative;
      @include res(padding-bottom,.3rem, (md:20px));
      .swiper-slide{
        >div{
          display: flex;
          align-items: center;
          justify-content: center;
          @include res(height,1rem,(md:80px,xs:60px));
        }
        img{
          display: block;
          width: auto;
          height: auto;
          @include res(max-width,50%);
          @include res(max-height,50%);
        }
      }
      .swiper-pagination{
        @include res(bottom,-.1rem,(md:-10px));
        .swiper-pagination-bullet{
          background-color: transparent;
          transition: $anime-duration $anime-bezier;
          position: relative;
          border-radius: 0;
          width: 18px;
          margin: 0;
          @include res(width,.18rem,(md:18px));
          @include res(height,.4rem,(md:20px));
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
            @include res(width,.07rem,(md:7px));
            @include res(height,.07rem,(md:7px));
          }
        }
      }
    }
    .link{
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(margin-top,.2rem, (md:10px));
      &::after,&::before{
        content: '';
        display: block;
        flex-grow: 1;
        background-color: #000;
        @include res(height,.01rem, (md:1px));
      }
      .common-btn{
        @include res(margin-left,.15rem, (md:10px));
        @include res(margin-right,.15rem, (md:10px));
      }
    }
  }
}

.section2{
  position: relative;
  z-index: 1;
  // overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  @include res(margin-top,.7rem,(md:0px));
  @include res(margin-bottom,.5rem,(md:10px,sm:0));
  @include res(background,#e0e0e0);
  @include res(display,flex);
  @include res(height,calc(100vh - 1.2rem),(md:auto));
  @include res(padding-left,8%, (md:10px));
  @include res(padding-right,8%, (md:10px));
  @include res(padding-top, 0,(md:30px));
  @include res(padding-bottom, 0,(md:30px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    background-color: #e0e0e0;
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    @include res(height,.7rem,(md:0px));
  }
  .sec2-text{
    top: 43%;
    z-index: 5;
    @include res(text-align,nnull,(xs:center));
    @include res(transform,translateY(-50%),(xs:translateY(0)));
    @include res(position,absolute,(xs:static));
    @include res(width, 29%,(sm:40%,xs:100%));
    @include res(right,18.75%, 5 / 18.75);
    @include res(margin-bottom,0,(xs:20px));
    .title{
      font-weight: bold;
      color: #000;
      @include res(font-size,.42rem,(md:26px));
      @include res(margin-bottom,.15rem,(md:10px,xs:5px));
    }
    .desc{
      @include res(line-height, 1.88, 1.6 / 1.88);
      @include res(font-size,.18rem,(md:16px));
      @include res(margin-bottom,.3rem,(md:15px,xs:10px));
    }
    .link{
      .common-btn{
        border-color: #868686;
        &:hover{
          .icon{
            background-color: transparent;
          }
        }
        .icon{
          background-color: $color-main;
          &::after{
            @include res(background-color,$color-main);
          }
          .iconfont{
            color: #fff;
          }
        }
        .t{
          @include res(width,1.15rem, (md:81px));
        }
      }
    }
  }
  .map-cont{
    width: 100%;
    margin: 0 auto;
    position: relative;
    white-space: nowrap;
    @include res(display,block);
    .map{
      display: block;
      width: 100%;
      height: auto;
      transition: opacity $anime-duration $anime-bezier;
      @include res(transform,scale(0),(xs:scale(1)));
    }
    .location-list{
      color: #000;
      @include res(font-size,.17rem,(md:12px));
      .list1{
        li{
          position: absolute;
          z-index: 2;
          @include res(opacity,0,(xs:1));
          @include res(width,.01rem,(md:1px));
          @include res(width,.01rem,(md:1px));
          // &:hover{
          //   .iconfont{
          //     color: #e60012;
          //     margin-bottom: .05rem;
          //   }
          // }
          &::before,&::after{
            content: '';
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate3d(-50%,-50%,0) scale(0);
            background-color: rgba(212,221,233,1);
            z-index: -1;
            transform-origin: center;
            animation: wave 2s infinite;
            transition: $anime-duration $anime-bezier;
            @include res(width,1.6rem, (md:100px,sm:70px,xs:50px));
            @include res(height,1.6rem, (md:100px,sm:70px,xs:50px));
          }
          // .iconfont{
          //   border-radius: 50%;
          //   background-color: $color-main;
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate3d(-50%,-50%,0);
          //   @include res(width,.25rem,(md:16px,sm:14px,xs:10px));
          //   @include res(height,.25rem,(md:16px,sm:14px,xs:10px));
          //   &::before{
          //     display: none;
          //   }
          // }
          // span{
          //   border-radius: 50%;
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate3d(-50%,-50%,0);
          //   border: solid $color-main;
          //   @include res(border-width,.02rem,(md:1px));
          //   @include res(width,.38rem,(md:24px,sm:20px,xs:16px));
          //   @include res(height,.38rem,(md:24px,sm:20px,xs:16px));
          // }
          .iconfont{
            color: #233f77;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            line-height: 1;
            z-index: 2;
            transition: $anime-duration $anime-bezier;
            // animation: shak 5s linear infinite;
            @include res(font-size,.4rem, (md:20px));
          }
          span{
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-30%);
            top: 0;
            z-index: 1;
            background: url(./images/index_img_local_bg.png) no-repeat top center;
            background-size: contain;
            @include res(width,.56rem,(md:30px));
            @include res(height,.18rem,(md:10px));
          }
          &:nth-child(1){
            top: 25.5%;
            left: 28%;
            &::before{
              animation-delay: 0s;
            }
            &::after{
              animation-delay: .8s;
            }
            .iconfont{
              animation-delay: .1s;
            }
          }
          &:nth-child(2){
            top: 73%;
            left: 14.5%;
            &::before{
              animation-delay: .5s;
            }
            &::after{
              animation-delay: 1.3s;
            }
            .iconfont{
              animation-delay: 1s;
            }
          }
          &:nth-child(3){
            top: 91.5%;
            left: 13.5%;
            &::before{
              animation-delay: .3s;
            }
            &::after{
              animation-delay: 1.1s;
            }
            .iconfont{
              animation-delay: 2s;
            }
          }
          &:nth-child(4){
            top: 64.5%;
            left: 40%;
            &::before{
              animation-delay: .3s;
            }
            &::after{
              animation-delay: 1.1s;
            }
            .iconfont{
              animation-delay: 3s;
            }
          }
          &:nth-child(5){
            top: 80%;
            left: 51%;
            &::before{
              animation-delay: .5s;
            }
            &::after{
              animation-delay: 1.3s;
            }
            .iconfont{
              animation-delay: 4s;
            }
          }
          &:nth-child(6){
            top: 14%;
            left: 70.5%;
            &::before{
              animation-delay: .5s;
            }
            &::after{
              animation-delay: 1.3s;
            }
            .iconfont{
              animation-delay: 5s;
            }
          }
        }
      }
    }
  }
  &.show{
    .location-list{
      .list1,.list2,.list3{
        li{
          opacity: 1;
        }
      }
      .list1{
        li{
          &:nth-child(1){
            transition: opacity $anime-duration $anime-bezier 0s;
          }
          &:nth-child(2){
            transition: opacity $anime-duration $anime-bezier .1s;
          }
          &:nth-child(3){
            transition: opacity $anime-duration $anime-bezier .2s;
          }
          &:nth-child(4){
            transition: opacity $anime-duration $anime-bezier .3s;
          }
          &:nth-child(5){
            transition: opacity $anime-duration $anime-bezier .4s;
          }
          &:nth-child(6){
            transition: opacity $anime-duration $anime-bezier .5s;
          }
        }
      }
    }
  }
}

@keyframes wave {
  0%{
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100%{
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
@keyframes shak {
  0%{
    color: $color-main;
  }
  13%{
    color: #fff;
  }
  25%{
    color: $color-main;
  }
  100%{
    color: $color-main;
  }
}

.section3{
  position: relative;
  // background-color: $color-bg-dark;
  &.fixed{
    .cont{
      position: fixed;
      top: 1.2rem;
    }
  }
  &.abs{
    .cont{
      position: absolute;
      top: auto;
      bottom: 0;
    }
  }
  .cont{
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    @include res(position,absolute,(md:static));
    @include res(display,flex,(md:block));
    @include res(height,calc(100vh - 1.2rem),(md:auto));
    @include res(padding-top,0,(md:30px));
    @include res(padding-bottom,0,(md:30px));
    .section3-title{
      width: 100%;
      @include res(margin-bottom,.5rem,(md:20px));
      .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .index-common-title{
          @include res(color,#000);
        }
        .progress{
          flex-grow: 1;
          background-color: rgba(0,0,0,.2);
          position: relative;
          overflow: hidden;
          @include res(height,.01rem,(md:1px));
          @include res(margin-left,.4rem, (md:10px));
          @include res(margin-right,.4rem, (md:10px));
          >div{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            background-color: #e60012;
            @include res(height,.01rem,(md:1px));
          }
        }
      }
    }
    ul{
      align-items: stretch;
      @include res(justify-content,flex-start,(md:space-between));
      @include res(flex-wrap,nowrap,(md:wrap));
      @include res(display,inline-flex,(xs:block));
      @include res(padding-left,1.5rem,(md:5%));
      @include res(padding-right,1.5rem,(md:5%));
      li{
        position: relative;
        flex-shrink: 0;
        z-index: 1;
        @include res(width,22vw,(lg:28vw,md:48%,xs:100%));
        @include res(height,60vh,(md:500px,sm:400px,xs:350px));
        @include res(margin-bottom,.5rem, (md:20px));
        &::after{
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -2;
          background-color: $color-bg-dark;
          transition: $anime-duration $anime-bezier;
        }
        &:not(:last-child){
          @include res(margin-right,.6rem,(md:0));
        }
        &:hover{
          &::after{
            @include res(transform,scale(1.1),(md:scale(1.05)));
          }
          .item-bg{
            opacity: .6;
            box-shadow: 0px .1rem .25rem rgba(0,0,0,.6);
            @include res(transform,scale(1.1),(md:scale(1.05)));
          }
          .item-text a .desc{
            opacity: 1;
            transform: translateY(0);
          }
        }
        .item-bg{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: $anime-duration $anime-bezier;
          @include res(opacity,.8,(md:.6));
        }
        .item-text{
          height: 100%;
          color: #fff;
          a{
            display: flex;
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: space-between;
            @include res(padding-left,.3rem,(md:20px));
            @include res(padding-right,.3rem,(md:20px));
            @include res(padding-top,.4rem,(md:30px));
            @include res(padding-bottom,.55rem,(md:30px)5);
            .tag{
              display: flex;
              @include res(margin-bottom,.45rem,(md:15px));
              span{
                flex-shrink: 0;
                display: inline-flex;
                border: .01rem solid #fff;
                align-items: center;
                border-radius: .5rem;
                @include res(font-size, .14rem,(md:14px));
                @include res(padding-left,.15rem,(md:15px));
                @include res(padding-right,.15rem,(md:15px));
                @include res(padding-top,.05rem,(md:5px));
                @include res(padding-bottom,.05rem,(md:5px));
                &:not(:last-child){
                  @include res(margin-right,.05rem,(md:5px));
                }
                .iconfont{
                  line-height: 1;
                  @include res(font-size, .22rem,(md:20px));
                  @include res(margin-right, .1rem,(md:10px));
                }
              }
            }
            .desc{
              transition: $anime-duration $anime-bezier;
              @include res(opacity,0,(md:1));
              @include res(transform,translateY(20%),(md:translateY(0)));
            }
            .title{
              font-weight: bold;
              @include res(border-bottom, .01rem solid rgba(255,255,255,.5),(md:1px solid rgba(255,255,255,.5)));
              @include res(font-size, .3rem,(md:20px));
              @include res(padding-bottom, .2rem,(md:10px));
            }
          }
        }
      }
    }
  }
}

.section4{
  position: relative;
  background: url(./images/index_service_bg.jpg) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  @include res(display,flex,(xs:block));
  @include res(height, calc(100vh - 1.2rem),(md:600px,xs:auto));
  @include res(padding-left,15.6%,(md:8%,sm:6.5%,xs:5%));
  @include res(padding-right,15.6%,(md:8%,sm:6.5%,xs:5%));
  @include res(padding-top,0,(xs:30px));
  @include res(padding-bottom,0,(xs:30px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255,255,255,.6);
  }
  .cont{
    position: relative;
    z-index: 2;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    @include res(display,flex,(xs:block));
    >.left{
      flex-shrink: 0;
      background: rgba(255,255,255,.8);
      box-sizing: border-box;
      @include res(padding-left,.5rem,(md:30px,xs:15px));
      @include res(padding-right,.5rem,(md:30px,xs:15px));
      @include res(padding-top,.8rem,(md:40px,xs:20px));
      @include res(padding-bottom,.7rem,(md:40px,xs:20px));
      @include res(width,40%,(xs:100%));
      .title{
        font-weight: bold;
        color: $color-main;
        @include res(font-size,.42rem,(md:30px,xs:24px));
        @include res(margin-bottom,.3rem,(md:15px));
      }
      .desc{
        color: #000;
        @include res(line-height,2.22, 1.8 / 2.22);
        @include res(font-size,.18rem,(md:16px));
        @include res(margin-bottom,1rem,(md:50px,xs:30px));
      }
      .tab{
        display: flex;
        align-items: center;
        justify-content: center;
        .item{
          text-align: center;
          color: $color-main;
          cursor: pointer;
          &:not(:last-child){
            @include res(margin-right,.7rem,(md:30px,xs:20px));
          }
          &.active{
            .icon{
              color: #fff;
              background-color: $color-main;
            }
          }
          .icon{
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $anime-duration $anime-bezier;
            @include res(border,.01rem solid $color-main, (md:1px solid $color-main));
            @include res(margin-bottom,.1rem, (md:10px));
            @include res(width,.58rem, (md:50px));
            @include res(height,.58rem, (md:50px));
            .iconfont{
              @include res(font-size,.4rem, (md:36px));
            }
          }
          .t{
            font-weight: bold;
            @include res(letter-spacing,.05rem, (md:3px));
            @include res(padding-left,.05rem, (md:3px));
            @include res(font-size,.2rem, (md:16px));
          }
        }
      }
    }
    >.right{
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      @include res(width,60%,(xs:100%));
      .img-swiper{
        width: 100%;
        @include res(height,100%,(xs:240px));
        .swiper{
          height: 100%;
          .swiper-slide{
            a{
              display: block;
              width: 100%;
              height: 100%;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .text-swiper{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        background-image: linear-gradient(to top,rgba(9,47,96,1),rgba(9,47,96,0));
        @include res(padding-bottom,.3rem,(md:20px,xs:10px));
        @include res(padding-top,.3rem,(md:20px,xs:10px));
        @include res(padding-left,.5rem,(md:30px,xs:20px));
        @include res(padding-right,.5rem,(md:30px,xs:20px));
        .swiper{
          .swiper-slide{
            height: auto;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: #fff;
            @include res(font-size,.18rem,(md:16px,xs:14px));
            .t{
              width: 100%;
              text-align: center;
            }
            .t-flex{
              width: 100%;
              align-items: flex-start;
              justify-content: space-between;
              @include res(display,flex,(md:block));
              .t-item{
                flex-shrink: 0;
                text-align: left;
                &:not(:last-child){
                  @include res(margin-bottom,0,(md:10px,xs:5px));
                }
                .desc-title{
                  position: relative;
                  font-weight: bold;
                  @include res(font-size,.18rem, (md:14px));
                  @include res(margin-bottom,.1rem,(md:5px,xs:0));
                  &::after{
                    content: '';
                    display: block;
                    background-color: #a2aec0;
                    position: absolute;
                    top: 48%;
                    transform: translateY(-50%);
                    @include res(left,-.1rem, (md:-8px));
                    @include res(width,.03rem, (md:2px));
                    @include res(height,.22rem, (md:18px));
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.section5{
  background-color: #e7e7e7;
  @include res(padding-top,.8rem, (md:30px));
  @include res(padding-bottom,.8rem, (md:30px));
  
  .section5-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(margin-bottom,.45rem, (md:15px));
    .index-common-title{
      color: #000;
    }
    .line{
      flex-grow: 1;
      background-color: #000;
      @include res(height,.01rem, (md:1px));
      @include res(margin-left,.4rem, (md:10px));
      @include res(margin-right,.2rem, (md:10px));
    }
  }
  .sec5-swiper{
    @include res(padding-left, 12.5%, 5 / 12.5);
    @include res(padding-right, 12.5%, 5 / 12.5);
    .rel-cont{
      position: relative;
      .swiper-button-next,.swiper-button-prev{
        border: .01rem solid #000;
        padding: .02rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: none;
        border-radius: 50px;
        box-sizing: content-box;
        background-color: #000;
        color: #fff;
        pointer-events: all;
        opacity: .5;
        transition: opacity .4s;
        @include res(transform,translateY(-.5rem),(md:translateY(-34px)));
        @include res(width,.5rem,(md:30px));
        @include res(height,.5rem,(md:30px));
        &::after{
          font-family: 'iconfont';
          @include res(font-size, .16rem,(md:12px));
        }
        &:hover{
          opacity: 1;
        }
        &.swiper-button-disabled{
          opacity: .3;
        }
      }
      .swiper-button-next{
        @include res(right,auto,(sm:10px));
        @include res(left,103%,(sm:auto));
        &::after{
          content: '\e8d4';
        }
      }
      .swiper-button-prev{
        @include res(left,auto,(sm:10px));
        @include res(right,103%,(sm:auto));
        &::after{
          content: '\eaf3';
        }
      }
    }
  }
  .swiper{
    overflow: hidden;
    .swiper-slide{
      &:hover{
        .item-img b{
          transform: scale(1.1);
        }
      }
      .item-t{
        color: #000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        @include res(height,.8rem,(md:58px));
        @include res(font-size,.22rem, (md:16px));
        @include res(margin-top,.2rem, (md:10px));
      }
    }
    .swiper-pagination{
      position: static;
      @include res(margin-top,.25rem, (md:5px));
      .swiper-pagination-bullet{
        background-color: transparent;
        transition: $anime-duration $anime-bezier;
        position: relative;
        border-radius: 0;
        margin: 0;
        @include res(width,.18rem, (md:18px));
        @include res(height,.4rem, (md:20px));
        &::after{
          content: '';
          display: block;
          border-radius: 50%;
          background-color: #000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%,-50%,0);
          @include res(width,.07rem, (md:7px));
          @include res(height,.07rem, (md:7px));
        }
      }
    }
  }
}